@containerPadding: 60px;
@darkTextColor: #202124;
@inputBorderColor: #dadce0;
@primaryColor: #01579b;
@labelColor: #a3a8ab;

@mobile: ~"screen and (max-width: 600px)";


body {
  margin: 0;
  padding: 0;
  font-family: 'Titillium Web', sans-serif;
}

.login-container {
  background: #fff;
  padding: @containerPadding;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border: 1px solid #dadce0;
  width: 448px - (@containerPadding * 2);
  height: 570px - (@containerPadding * 2);

  &--logo {
    text-align: center;
  }

  &--header {
    color: @darkTextColor;
    text-align: center;
    font-size: 18px;
  }

  &--form {
    .form-error {
      color: red;
      text-align: center;
    }
    .input-container {
      width: 100%;
      position: relative;
      margin-bottom: 20px;

      input {
        width: 100%;
        background: #fff;
        color: @darkTextColor;
        border: 1px solid @inputBorderColor;
        border-radius: 4px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        padding: 12px 14px;
        font-size: 16px;
        box-sizing: border-box;
        outline: none;
        -webkit-appearance: none;
        &:focus {
          border: 1px solid @primaryColor;
        }
      }

      .label {
        position: absolute;
        top: -12px;
        margin-left: 8px;
        background: #fff;
        padding: 0 6px;
        color: @labelColor;
        border-radius: 4px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        -webkit-transition: top 150ms cubic-bezier(0.4,0,0.2,1);
        transition: top 150ms cubic-bezier(0.4,0,0.2,1);
        white-space: nowrap;
      }
    }

    .form-actions{
      display: flex;
      align-items: center;
      -webkit-align-items: center;
      justify-content: space-between;
      -webkit-justify-content: space-between;
      .sign-in-button{
        background: @primaryColor;
        -webkit-appearance: none;
        border: none;
        color: #fff;
        font-size: 14px;
        padding: 9px 18px;
        border-radius: 4px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        outline: none;
        text-transform: uppercase;
        cursor: pointer;
        &:active {
          background: lighten(@primaryColor, 20%);
        }
        &:hover {
          background: lighten(@primaryColor, 10%);
        }
      }
    }

  }

  &--browser {
    color: @darkTextColor;
    text-align: center;
    font-size: 12px;
    margin-top: 45px;
    color: dimgray;
  }
}

a {
  text-decoration: none;
  color: @primaryColor;
  &:hover{
    text-decoration: underline;
  }
}

@-webkit-keyframes autofill {
  0%, 100% {
    color: #666;
    background: transparent;
    font-size: 16px;
  }
}
/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  font-size: inherit;
  -webkit-text-fill-color: inherit;
  -webkit-box-shadow: inherit;
  transition: background-color 5000s ease-in-out 0s;
}


.toggle-password{
  width: 18px;
  height: 18px;
  color: @labelColor;
  position: absolute;
  right: 10px;
  top: 15px;
  cursor: pointer
}

.languages-container{
  position: absolute;
  top: @containerPadding;
  right: @containerPadding;
  width: auto;
  display: flex;
  align-items: center;
  cursor: pointer;
  img {
    margin-right: 8px;
  }
}
.language-row{
  cursor: pointer;
  padding: 3px 0;
  a{
    color: #fff;
  }
  .language-flag{
    margin-right: 10px;
  }
}

@media @mobile {
  .login-container{
    border: none;
    width: 80%;
    margin-top: 10vh;
    padding: 0;
  }
  .languages-container
  {
    top: 20px;
    right: 20px;
  }
}
.sign-in-loader{
  background: #01579b;
  -webkit-appearance: none;
  border: none;
  color: #fff;
  font-size: 14px;
  padding: 9px 18px;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  .loader-wheel {
    animation: spin 1s infinite linear;
    border: 2px solid rgb(255, 255, 255);
    border-left: 2px solid #0d579a;
    border-radius: 50%;
    height: 13px;
    width: 13px;
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@import "~sweetalert2/dist/sweetalert2.min.css";
